import { FormEvent, useRef, useState } from "react";
import {
    Button,
    Flex,
    FormControl,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    FormLabel,
    Accordion,
    useToast,
    Divider
} from "@chakra-ui/react"
import bgLogo from "../assets/Isolation_Mode.svg"
import arrowRight from "../assets/icons/arrow_forward.svg"
import { credentialsRepo } from "../api/credentialsRepo";
import { format } from "date-fns"
import CustomAccordionItem from "../components/CustomAccordionItem";
import CustomTable from "../components/CustomTable";

interface StealerLog {
    uuid: string;
    antivirus: string;
    log_date: string | undefined;
    path: string;
    stealer_name: string;
}

interface SystemInfo {
    uuid: string;
    computer_name: string;
    country: string;
    hardware_id: string;
    ip_address: string;
    machine_id: string;
    machine_user: string;
    stealer_log: StealerLog;
}

interface Credential {
    uuid: string;
    software: string;
    host: string;
    domain: string;
    email_domain: string;
    local_part: string;
    password: string;
    username: string;
}

interface System {
    system_info: SystemInfo;
    credentials: Credential[];
    total_credentials: number;
    total_cred_pages: number;
    current_cred_page: number;
}

interface SystemSearchResponse {
    systems: System[];
    total_systems: number;
    total_pages: number;
    current_page: number;
}

export default function SearchInfoStealer() {
    const [search, setSearch] = useState<string>("");
    const [searchResults, setSearchResults] = useState<SystemSearchResponse | undefined>();
    const [isEmailDomainSearch, setIsEmailDomainSearch] = useState<boolean>(false);
    const pulseRef = useRef<HTMLDivElement>(null);
    const toast = useToast();
    const [accordionOpenedLog, setAccordionOpenedLog] = useState<string>();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const results = isEmailDomainSearch ? await credentialsRepo.searchEmailDomain(search.toLowerCase()) : await credentialsRepo.searchDomain(search.toLowerCase());
            setSearchResults(results.data);
        } catch (e: any) {
            toast({
                title: "No results found",
                status: "error"
            })
        }
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            background: `url(${bgLogo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            minHeight: "100vh",
            backgroundSize: "contain"
        }}>
            <div className="content-wrapper" style={{ width: "81%", rowGap: "0px" }}>
                <form onSubmit={handleSubmit}>
                    <FormControl ref={pulseRef}>
                        <InputGroup className="searchInputWrapper1">
                            <Input
                                onChange={(e) => setSearch((e.target.value))}
                                value={search}
                                placeholder={isEmailDomainSearch ? 'Search by email domain' : 'Search by domain'}
                                size='lg'
                            />
                            <InputRightElement className="input-string-indicator" style={{ height: "100%", opacity: search.length > 3 ? 1 : 0 }}>
                                <Button type="submit" className="button-submit1" borderRightRadius={"4px"} height="100%">Search<Image src={arrowRight} /></Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </form>

                <Flex alignItems="flex-start" flexDir={"column"} mt={2} mb={4}>
                    <FormLabel htmlFor="search-type-switch" mb="0">
                        Search by:
                    </FormLabel>
                    <Flex>
                        <Button onClick={() => setIsEmailDomainSearch(false)} colorScheme={isEmailDomainSearch ? "gray" : "red"}>Domain</Button>
                        <Button onClick={() => setIsEmailDomainSearch(true)} colorScheme={!isEmailDomainSearch ? "gray" : "red"}>Email Domain</Button>
                    </Flex>
                </Flex>

                {searchResults && (
                    <>
                        {/* <Box mt={8} width="100%">
                            <VStack spacing={4} align="stretch">
                                <Heading size="md">Search Results</Heading>
                                <Text>Total Systems: {searchResults.total_systems}</Text>
                                <Accordion allowMultiple>
                                    {searchResults.systems.map((system: any) => (
                                        <AccordionItem key={system.system_info.uuid}>
                                            <h2>
                                                <AccordionButton _expanded={{ background: "rgba(34, 34, 34, 1)" }}>
                                                    <Box flex="1" textAlign="left">
                                                        {system.system_info.computer_name} - {system.system_info.ip_address}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4} backgroundColor="rgba(34, 34, 34, 1)">
                                                <Table variant="simple" size="sm">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Log Date</Th>
                                                            <Th>Country</Th>
                                                            <Th>Machine User</Th>
                                                            <Th>Antivirus</Th>
                                                            <Th>Stealer Name</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr>
                                                            <Td>{format(system.system_info.stealer_log.log_date, "yyyy-MM-dd HH:mm:ss")}</Td>
                                                            <Td>{system.system_info.country}</Td>
                                                            <Td>{system.system_info.machine_user}</Td>
                                                            <Td>{system.system_info.stealer_log.antivirus}</Td>
                                                            <Td>{system.system_info.stealer_log.stealer_name}</Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>
                                                <Heading size="sm" mt={4} mb={2}>Credentials</Heading>
                                                <Table variant="simple" size="sm">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Software</Th>
                                                            <Th>Host</Th>
                                                            <Th>Email Domain</Th>
                                                            <Th>Username</Th>
                                                            <Th>Password</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {system.credentials.map((cred: any) => (
                                                            <Tr key={cred.uuid}>
                                                                <Td>{cred.software}</Td>
                                                                <Td>{cred.host}</Td>
                                                                <Td>{cred.email_domain}</Td>
                                                                <Td>{cred.username}</Td>
                                                                <Td>{cred.password}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </VStack>
                        </Box> */}
                        <Accordion allowToggle width={"100%"}>
                            {searchResults && searchResults.systems.map((systemObj, i) =>
                                <CustomAccordionItem key={i}
                                    accordionTitle={systemObj.system_info.computer_name}
                                    accordionText={`${systemObj.system_info.ip_address || "Unknown"} - Log Date: ${systemObj.system_info.stealer_log.log_date ? format(systemObj.system_info.stealer_log.log_date, "yyyy-MM-dd HH:mm:ss") : "Unknown"} - ${systemObj.system_info.country} - Machine User: ${systemObj.system_info.machine_user} - Antivirus: ${systemObj.system_info.stealer_log.antivirus} - Logstealer: ${systemObj.system_info.stealer_log.stealer_name}`}
                                    // accordionText={systemObj.system_info.machine_user || "Unknown"}
                                    onClick={() => { setAccordionOpenedLog(systemObj.system_info.uuid) }}>
                                    <>
                                        <CustomTable
                                            headings={["SOFTWARE", "HOST", "EMAIL DOMAIN", "USERNAME", "PASSWORD"]}
                                            rowsData={
                                                systemObj.credentials.map(cred => [cred.software, cred.host, cred.email_domain, cred.username, cred.password])
                                            } />
                                            <Divider />
                                        {window.innerWidth < 768 && <CustomTable
                                            headings={["Log Date", "IP", "Country", "Machine User", "Antivirus", "Stealer Name"]}
                                            rowsData={
                                                [[
                                                    systemObj.system_info.stealer_log.log_date ? format(systemObj.system_info.stealer_log.log_date, "yyyy-MM-dd HH:mm:ss") : "Unknown",
                                                    systemObj.system_info.ip_address || "Unknown",
                                                    systemObj.system_info.country,
                                                    systemObj.system_info.machine_user,
                                                    systemObj.system_info.stealer_log.antivirus,
                                                    systemObj.system_info.stealer_log.stealer_name
                                                ]]
                                            } />}
                                        <Flex className="data-logs-pagination-wrapper" justifyContent={"space-between"}>
                                            {/* <Pagination
                                                currentPage={ipsLogsPage}
                                                setPage={setIpsLogsPage}
                                                maxPage={cveIpLogs[ipObj.ip].pagination.maxPage}
                                            /> */}
                                        </Flex>
                                    </>
                                </CustomAccordionItem>
                            )}
                        </Accordion>
                    </>
                )}

                {/* <Flex className="home-footer" alignItems={"center"} justifyContent={"space-between"} position="fixed" bottom="24px" left="24px" right="24px">
                    <Image src={HomeFooterLogo} h={"48px"} />
                    <Flex columnGap={"45px"}>
                        <Text fontSize={"12px"} color={"rgba(167, 167, 167, 1)"}>CONTACT SUPPORT</Text>
                        <Text fontSize={"12px"} color={"rgba(167, 167, 167, 1)"}>© 2024 Sphere by Alphatechs</Text>
                    </Flex>
                </Flex> */}
            </div>
        </div>
    )
}